import React from 'react';
import { useTranslation } from "react-i18next";

function Faq(props: any) {

    const { t } = useTranslation();

    return (
        <div className='content-wrapper'>
            <h2>{t("home_faq_t1")}</h2>
            <p>
                {t("home_faq_t1_p1")} <strong>{t("dice")}</strong> {t("and")} <strong>{t("elamaa")}</strong>.
            </p>
            <p>
                <strong>{t("dice")}</strong> {t("home_faq_t1_p2")}
            </p>
            <p>
                <strong>{t("elamaa")}</strong> {t("home_faq_t1_p3")}
            </p>
            <p>{t("home_faq_t1_p4")}</p>

            <h2>{t("home_faq_t2")}</h2>
            <p>{t("home_faq_t2_p1")}</p>
            <p>{t("home_faq_t2_p2")}</p>
            <p>{t("home_faq_t2_p3")}</p>

            <h2>{t("home_faq_t3")}</h2>
            <p>
                {t("home_faq_t3_p1")}
                <br />
                {t("home_faq_t3_p2")}
            </p>

            <h2>{t("home_faq_t4")}</h2>
            <p>{t("home_faq_t4_p1")}</p>
            <p>{t("home_faq_t4_p2")}</p>
            <p>
                {t("home_faq_t4_p3")}
                <br />
                {t("home_faq_t4_p4")}
            </p>
        </div>
    );
}

export default Faq;