import * as React from 'react';
import { Link } from "@reach/router";
import "./menu.scss";
import { PAGES } from '../../utils/Constants';
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "../../store/Hooks";
import { changePage, selectConfig } from "../../store/config/ConfigSlice";

const Menu = () => {

    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const currentpage = useAppSelector(selectConfig).currentpage;

    const handleClick = (_page: number) => {
        dispatch(changePage(_page));
    }

    const setSelected = (_link: number) => {
        return _link == currentpage ? "selected" : "";
    }

    return (
        <nav>
            {
                PAGES.map((elem, idx) => {
                    return <Link key={idx} className={setSelected(elem.type)} onClick={() => handleClick(elem.type)} to={elem.to}>{t(elem.title)}</Link>
                })
            }
        </nav>
    );
};

export default Menu;