import React from 'react';
import Third from '../components/column/Third';
import { useTranslation } from "react-i18next";

function Contact(props: any) {

    const { t } = useTranslation();

    return (
        <div className='content-wrapper'>
            <h2>{t("gallery")}</h2>
            <Third>
                <img src='./img/photos/aqua_1.jpg' />
                <img src='./img/photos/crystal_pink.jpg' />
                <img src='./img/photos/space.jpg' />
            </Third>
            <Third>
                <img src='./img/photos/yellow_vertical.jpg' />
                <img src='./img/photos/lime.jpg' />
            </Third>
            <Third>
                <img src='./img/photos/tech.jpg' />
                <img src='./img/photos/vegan.jpg' />
                <img src='./img/photos/pink.jpg' />
            </Third>
            <div className='clearBoth'></div>
        </div>
    );
}

export default Contact;