import React from 'react';
import Editor from '../components/editor/Editor';
import { useTranslation } from "react-i18next";

function EditorPage(props: any) {
    const { t } = useTranslation();
    return (
        <div className='content-wrapper'>
            <h2>{t("editor")}</h2>
            <p>{t("editor_p1")}</p>
            <p>{t("editor_p2")}</p>
            <p>{t("editor_p3")}</p>
            <ol>
                <li>{t("editor_p4")}</li>
                <li>{t("editor_p5")}</li>
                <li>{t("editor_p6")}</li>
            </ol>
            <Editor></Editor>
            <p>{t("editor_p_end")}</p>
            <div className='pImageEditor'>
                <img src="./img/editor/sample/pink.png" />
                <img src="./img/editor/sample/red.png" />
                <img src="./img/editor/sample/yellow.png" />
            </div>
        </div>
    );
}

export default EditorPage;