import React, { createRef } from 'react';
import emailjs from '@emailjs/browser';
import './contactform.scss';
import { useTranslation } from "react-i18next";
import { emailConf, formStatus } from '../../utils/Constants';
import Spinner from '../spinner/Spinner';

const ContactForm = (props: any) => {
    const form = createRef<HTMLFormElement>();

    const { t } = useTranslation();

    const [errorStatus, setStatus] = React.useState(formStatus.WAITING);
    const [user_name, setUserName] = React.useState("");
    const [user_email, setUserEmail] = React.useState("");
    const [user_message, setUserMessage] = React.useState("");
    const [user_code, setUserCode] = React.useState("");

    const sendEmail = (e: any) => {
        e.preventDefault();

        if (form.current) {
            setStatus(formStatus.PENDING);
            emailjs.sendForm(emailConf.serviceId, emailConf.templateId, form.current, emailConf.userId)
                .then((result) => {
                    setStatus(formStatus.SUCCESS);
                    emptyForm(form.current);
                }, (error) => {
                    setStatus(formStatus.ERROR);
                });
        }
    };


    const emptyForm = (_form: any) => {
        setUserName("");
        setUserEmail("");
        setUserMessage("");
        setUserCode("");
    }


    return (
        <form ref={form} onSubmit={sendEmail} >
            <label className='required'>{t("contact_name")}</label>
            <input type="text" name="user_name" required value={user_name} onChange={(e) => { setUserName(e.target.value) }} />
            <label className='required'>{t("contact_email")}</label>
            <input type="email" name="user_email" required value={user_email} onChange={(e) => { setUserEmail(e.target.value) }} />
            <label className='required'>{t("contact_message")}</label>
            <textarea name="user_message" required value={user_message} onChange={(e) => { setUserMessage(e.target.value) }} />
            <label>{t("contact_code")}</label>
            <input type="text" name="user_dicecode" value={user_code} onChange={(e) => { setUserCode(e.target.value) }} />
            <input type="submit" value={t("send_message").toString()} />
            <FormStatus errorStatus={errorStatus} ></FormStatus>
            <p>{t("required")}</p>
        </form>
    );
};



const FormStatus = (props: any) => {

    const { t } = useTranslation();

    const renderStatus = () => {
        switch (props.errorStatus) {
            case formStatus.SUCCESS:
                return <p className='centered'>{t("email_ok")}</p>;
            case formStatus.ERROR:
                return <p className='centered red'>{t("email_ko")}</p>;
            case formStatus.PENDING:
                return <Spinner />;
        }
    }

    return (
        <>
            {renderStatus()}
        </>
    )
}


export default ContactForm;