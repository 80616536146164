import React from 'react';
import "./logo.scss";

function Logo(props: any) {
    return (
        <div className='logo'>
            <div>
                <img src='./img/logo_black_transparent.png' />
            </div>
            <span>Dicelämää</span>
        </div>
    );
}

export default Logo;