import React from 'react';
import "./column.scss";

function Half(props: any) {
    return (
        <div className='wrapper-part wrapper-half'>
            {props.children}
        </div>
    );
}

export default Half;