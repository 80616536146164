import React from 'react';
import "./column.scss";

function Third(props: any) {
    return (
        <div className='wrapper-part wrapper-third'>
            {props.children}
        </div>
    );
}

export default Third;