import React from 'react';
import Menu from '../../menu/Menu';
import "./footer.scss";

const Footer = () => {
    return (
        <footer>
            <Menu />
        </footer>
    );
};

export default Footer;