import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { PAGES_TYPE } from '../../utils/Constants'
import type { RootState } from '../Store'

interface ConfigState {
    currentpage: number
}

const initialState: ConfigState = {
    currentpage: PAGES_TYPE.HOME
}

export const configSlice = createSlice({
    name: 'counter',
    initialState,
    reducers: {
        changePage: (state, action: PayloadAction<number>) => {
            state.currentpage = action.payload
        }
    }
})

export const { changePage } = configSlice.actions
export const selectConfig = (state: RootState) => state.config
export default configSlice.reducer