import React from 'react';
import Flag from '../icons/Flag';
import Social from '../icons/Social';
import "./socialarea.scss";

function SocialArea(props: any) {
    return (
        <div className='socialArea'>
            <Social link="https://www.instagram.com/dicelamaa/" image="./img/icons/ig.png" ></Social>
            <Social link="https://www.facebook.com/dicelamaa" image="./img/icons/fb.png" ></Social>
            <Flag lang="it" image="./img/icons/it.png"></Flag>
            <Flag lang="en" image="./img/icons/en.png"></Flag>
        </div>
    );
}

export default SocialArea;