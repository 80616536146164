import React from 'react';
import Half from '../components/column/Half';
import { useTranslation } from "react-i18next";

function Home(props: any) {

    const { t } = useTranslation();

    return (
        <div className='content-wrapper'>
            <Half>
                <img src='./img/home.png' />
            </Half>
            <Half>
                <h1>{t("home_main_title")}</h1>
                <p>{t("home_main_p1")}</p>
                <p>{t("home_main_p5")}</p>
                <p>
                    {t("home_main_p2")}
                    <br />
                    {t("home_main_p2_1")}
                    <br />
                    {t("home_main_p2_2")}
                </p>
                <p>
                    {t("home_main_p3")}
                    <br />
                    {t("home_main_p3_1")}
                </p>
                <p>{t("home_main_p4")}</p>
                <p>{t("home_main_p6")}</p>
                <p>{t("home_main_p7")}</p>
                <p>
                    {t("home_main_p8")}
                    <br />
                    {t("home_main_p8_2")}
                    <br />
                    {t("home_main_p8_3")}
                    <br />
                    {t("home_main_p8_4")}
                    <br />
                    {t("home_main_p8_5")}
                </p>
                <p>{t("home_main_p8_6")}</p>
            </Half>
            <div className='clearBoth'></div>
        </div>
    );
}

export default Home;