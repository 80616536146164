import * as React from 'react';
import Layout from './components/layout/Layout';
import { Router } from "@reach/router";
import Home from './pages/Home';
import Contact from './pages/Contact';
import EditorPage from './pages/EditorPage';
import Shop from './pages/Shop';
import Gallery from './pages/Gallery';
import Faq from './pages/Faq';


const App = () => {
  return (
    <div className="App">
      <Layout>
        <Router primary={false}>
          <Home path="/" />
          <Shop path="/shop"></Shop>
          <EditorPage path="/editor"></EditorPage>
          <Contact path="/contact"></Contact>
          <Gallery path="/gallery"></Gallery>
          <Faq path="/faq"></Faq>
        </Router>
      </Layout>
    </div>
  );
}

export default App;
