import React from 'react';
import "./spinner.scss";

function Spinner(props: any) {
    return (
        <div className='spinnerContainer'>
            <div className="spinner">
                <div className="bounce1"></div>
                <div className="bounce2"></div>
                <div className="bounce3"></div>
            </div>
        </div>
    );
}

export default Spinner;