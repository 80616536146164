import React from 'react';
import Color from '../../utils/Color';
import { NUM_COLORS, FILL } from '../../utils/Constants';
import Solver from '../../utils/Solver';
import { RgbaColorPicker } from "react-colorful";
import * as Utils from '../../utils/Utils';
import { useTranslation } from "react-i18next";
import "./editor.scss";

const Editor = () => {

    const [ncolor, setNColor] = React.useState("#aaaaaa");
    const [fIndex, setFIndex] = React.useState(0);
    const [bcolor, setBColor] = React.useState({ r: 255, g: 255, b: 255, a: 1 });
    const [dCode, setDCode] = React.useState("");

    const { t } = useTranslation();

    const numberColorClick = (_c: string) => {
        setNColor(_c);
    }

    const setBodyColor = (_color: any) => {
        const color = new Color(_color.r, _color.g, _color.b);
        const solver = new Solver(color);
        const result = solver.solve();
        return result.filter + `opacity(${_color.a * 100}%)`;
    }

    const setNumberColor = (_color: string) => {
        let rgb = Utils.hexToRgb(_color);
        const color = new Color(rgb[0], rgb[1], rgb[2]);
        const solver = new Solver(color);
        const result = solver.solve();
        return result.filter;
    }

    const setCode = () => {
        let text = `{"n":"${ncolor}","b":${JSON.stringify(bcolor)},"i": ${fIndex}}`;
        setDCode(btoa(text));
    }

    return (
        <div className='editorContainer'>
            <div className='editorPart imageEditor'>
                <div className='imagesContainer'>
                    <img className='fill' src={FILL[fIndex].src} />
                    <img className='body' style={{ "filter": setBodyColor(bcolor) }} src='/img/editor/body/white.png' />
                    <img className='shadows' src='/img/editor/shadow/ombre.png' />
                    <img className='numbers' style={{ "filter": setNumberColor(ncolor) }} src="/img/editor/numbers/black.png" />
                </div>
            </div>
            <div className='editorPart'>
                <div className='numberContainer'>
                    <div className='editorTitle'>{t("numbers")}</div>
                    {
                        NUM_COLORS.map((elem, idx) => {
                            return <div key={idx} className='numColor' title={elem.name} onClick={() => numberColorClick(elem.color)}><div style={{ "background": elem.color }}></div></div>
                        })
                    }
                </div>
                <div className='bodyContainer'>
                    <div className='editorTitle'>{t("body")}</div>
                    <RgbaColorPicker color={bcolor} onChange={setBColor} />
                </div>
                <div className='innerContainer'>
                    <div className='editorTitle'>{t("fill")}</div>
                    {
                        FILL.map((elem, idx) => {
                            return <div key={"n" + idx} className='numColor' title={elem.name} onClick={() => setFIndex(idx)}><div style={{ "backgroundImage": "url(" + elem.src + ")" }}></div></div>
                        })
                    }
                </div>
                <div className='clearBoth'></div>
            </div>
            <div className='editorPart'>
                <button onClick={() => setCode()}>{t("generate_code")}</button>
            </div>
            <div className='editorPart'>
                <textarea readOnly value={dCode}></textarea>
            </div>
        </div >
    );
}

export default Editor;