import { configureStore } from '@reduxjs/toolkit'
import ConfigReducer from "./config/ConfigSlice"

export const store = configureStore({
    reducer: {
        config: ConfigReducer
    }
})

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch