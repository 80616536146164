export enum PAGES_TYPE {
    HOME,
    EDITOR,
    GALLERY,
    SHOP,
    CONTACT,
    FAQ
}

export const PAGES = [
    {
        title: "home",
        to: "/",
        type: PAGES_TYPE.HOME
    },
    {
        title: "editor",
        to: "editor",
        type: PAGES_TYPE.EDITOR
    },
    {
        title: "gallery",
        to: "gallery",
        type: PAGES_TYPE.GALLERY
    },
    {
        title: "shop",
        to: "shop",
        type: PAGES_TYPE.SHOP
    },
    {
        title: "contacts",
        to: "contact",
        type: PAGES_TYPE.CONTACT
    },
    {
        title: "faq",
        to: "faq",
        type: PAGES_TYPE.FAQ
    }
]

export const NUM_COLORS = [
    { color: "#000000", name: "Black" },
    { color: "#C77B30", name: "Bronze" },
    { color: "#D6A500", name: "Gold" },
    { color: "#58ca5b", name: "Green" },
    { color: "#fba704", name: "Orange" },
    { color: "#f99cff", name: "Pink" },
    { color: "#800fba", name: "Purple" },
    { color: "#f34c11", name: "Red" },
    { color: "#bbbbbb", name: "Silver" },
    { color: "#ffffff", name: "White" },
    { color: "#f8fb04", name: "Yellow" },
    { color: "#0694FF", name: "Blue" }
]

export const FILL = [
    { name: "Empty", src: "/img/editor/fill/empty.png" },
    { name: "Glitter (same color)", src: "/img/editor/fill/glitter.png" },
    { name: "Holographic paper", src: "/img/editor/fill/holo.png" },
    { name: "Gold pieces", src: "/img/editor/fill/gold.png" },
    { name: "Silver pieces", src: "/img/editor/fill/silver.png" },
    { name: "Gears", src: "/img/editor/fill/gears.png" },
    { name: "Motherboard piece", src: "/img/editor/fill/motherboard.png" },
    { name: "Yellow Stone", src: "/img/editor/fill/yellow_stone.png" },
    { name: "Green Stone", src: "/img/editor/fill/green_stone.png" },
    { name: "Blue Stone", src: "/img/editor/fill/blue_stone.png" },
    { name: "Pink Stone", src: "/img/editor/fill/pink_stone.png" },
]

export const emailConf = {
    userId: "user_5wgqpAKw18foVceZZPphW",
    templateId: "template_tyanxdb",
    serviceId: "service_wsil2im"
}

export enum formStatus {
    WAITING,
    PENDING,
    SUCCESS,
    ERROR
}