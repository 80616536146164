import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from 'i18next-browser-languagedetector';

import it_res from './resources/it.json';
import en_res from './resources/en.json';


const resources = {
    en: {
        translation: en_res
    },
    it: {
        translation: it_res
    }
}

const options = {
    order: ['navigator', 'localStorage'],
    lookupLocalStorage: 'i18nextLng',
}

i18n
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        resources,
        fallbackLng: 'en',
        debug: true,
        detection: options,
        interpolation: {
            escapeValue: false
        }
    });

export const changeLanguage = (language: string) => {
    try {
        window.localStorage.setItem('i18nextLng', language)
        if ((window as any).sp !== undefined) {
            (window as any).sp.switchLanguage(language)
        }
        i18n.changeLanguage(language)
    } catch (error) {
        console.log(error);
    }
}

export default i18n;