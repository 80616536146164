import React from 'react';

function Shop(props: any) {
    return (
        <div>
            Shop
        </div>
    );
}

export default Shop;