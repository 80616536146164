import i18next from 'i18next';
import React from 'react';
import "./social.scss";

function Flag(props: any) {
    return (
        <div className='icon' onClick={() => { i18next.changeLanguage(props.lang) }} >
            <img src={props.image} />
        </div>
    );
}

export default Flag;