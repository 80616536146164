import React from 'react';
import "./header.scss";
import Logo from '../../menu/logo/Logo';
import Menu from "../../menu/Menu";
import SocialArea from '../../socialarea/SocialArea';

const Header = () => {

    const [opened, setOpened] = React.useState(false);

    const toggleMenu = () => {
        setOpened(!opened);
    }

    return (
        <header>
            <Logo />
            <div className={"closeContainer opened_" + opened}>
                <Menu />
                <SocialArea />
                <div className="menuButton" onClick={() => toggleMenu()}>
                    <div className='hambline line1'></div>
                    <div className='hambline line2'></div>
                    <div className='hambline line3'></div>
                </div>
            </div>
        </header>
    );
};

export default Header;