import React from 'react';
import Half from '../components/column/Half';
import ContactForm from '../components/contactform/ContactForm';
import { useTranslation } from "react-i18next";


const Contact = (props: any) => {

    const { t } = useTranslation();
    return (
        <div className='content-wrapper'>
            <Half>
                <h2>{t("contactme")}</h2>
                <p>{t("contact_p1")}</p>
                <p>{t("contact_p2")}</p>
            </Half>
            <Half>
                <ContactForm></ContactForm>
            </Half>
            <div className='clearBoth'></div>
        </div>
    );
};

export default Contact;