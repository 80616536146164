import React from 'react';
import "./social.scss";

function Social(props: any) {
    return (
        <a className='icon' target="_blank" href={props.link}>
            <img src={props.image} />
        </a>
    );
}

export default Social;